// @ts-nocheck
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';

declare function cwr(operation: string, payload): void;

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  permissionRolesUrl: string = env.config.domain + env.permissionRoles;
  headertitleMessageData = new Subject();
  headertitleMessgae$ = this.headertitleMessageData.asObservable();

  baseUrl: string = env.orgRole;
  roleUrl: string = env.config.domain + env.orgRole;
  termsConditionUrl: string = env.config.domain + env.getTermsCondition;
  updateTermsConditionUrl: string = env.config.domain + env.updateTermsCondition;

  getdatasetlistUrl: string = env.config.domain + env.getdatasetlist;

  constructor(readonly http: HttpClient, public storageService: StorageService, readonly router: Router) {}

  sendMessage(title) {
    this.headertitleMessageData.next(title)
  }

  getLogout() {
    const logoutUrl = env.platformApiUrl + env.apiLogout;
    return this.http.get(logoutUrl);
  }

  getCurrentUserRole(useruuid) {
    return this.http.post(this.roleUrl, useruuid);
  }
  
  getTermsConditionStatus() {
    return this.http.get(this.termsConditionUrl);
  }

  updateTermsConditionStatus(userUUID: string) {
    const url = this.updateTermsConditionUrl + '/' + userUUID;
    return this.http.put(url, {});
  }

  getPermissionRoles(orgKey: string) {
    const permissionRolesUrl = this.permissionRolesUrl+"/"+ orgKey;
    return this.http.get(permissionRolesUrl);
  }

  getAdminRoleAccess() {
    const url = env.config.domain + env.adminroleaccess;
    return this.http.get(url);
  }

  getClassification(projectKey: string, userId: string ){
    const url = this.getdatasetlistUrl+"/"+projectKey+"/"+userId;
    return this.http.get(url);
  }
  navifyUsersList() {
    const url = 'https://api.appdevus.platform.navify.com/api/v1/tenants/7bd7154e-95ce-45f3-8885-4a9e0df7b05e/admin-users';
    return this.http.get(url);
  }

  rumConfig(){
    this.router.events.subscribe(event => {      
    });
  }



}
